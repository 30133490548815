(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Ajuntaments
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Ajuntaments', Ajuntaments);

  function Ajuntaments($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/ajuntaments/:id', {id: '@_id'}, {
      update: {
        method: 'PUT'
      }
    });
  }
}());
